@import "../../config/constant.scss";

.rb-input {
  border-radius: 2px;
  line-height: 28px;
  font-size: 14px;
  text-align: left;
  background: $rb-white;
  border: 1px solid $rb-light-grey;
  color: $rb-black;
  height: 40px;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $rb-dark-grey;
  }
  &.is-invalid {
    background-image: none;
  }
  &.search {
    padding-left: 40px;
    &+.icon-search {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;
    }
  }
}