@import '../../config/devices.scss';

.foodbam-agreement {

  .steps{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include respond-below(sm) {
      max-width: 100%;
      padding: 0% 10% 0% 10%;
      justify-content: space-between;
    }
  }

  iframe#foodbamTerms {
    min-height: 80vh;
  }

}
