//Text Sizes
.text-size {
  &-10 {
    font-size: 10px;
  }
  &-11 {
    font-size: 11px;
  }
  &-12 {
    font-size: 12px;
  }
  &-13 {
    font-size: 13px;
  }
  &-14 {
    font-size: 14px;
  }
  &-15 {
    font-size: 15px;
  }
  &-16 {
    font-size: 16px;
  }
  &-17 {
    font-size: 17px;
  }
  &-18 {
    font-size: 18px;
  }
  &-19 {
    font-size: 19px;
  }
  &-20 {
    font-size: 20px;
  }
  &-22 {
    font-size: 22px;
  }
  &-24 {
    font-size: 24px;
  }
  &-26 {
    font-size: 26px;
  }
  &-28 {
    font-size: 28px;
  }
  &-30 {
    font-size: 30px;
  }
  &-32 {
    font-size: 32px;
  }
  &-35 {
    font-size: 35px;
  }
  &-36 {
    font-size: 36px;
  }
  &-38 {
    font-size: 38px;
  }
  &-40 {
    font-size: 40px;
  }
  &-45 {
    font-size: 45px;
  }
  &-48 {
    font-size: 48px;
  }
  &-55 {
    font-size: 55px;
  }
  &-60 {
    font-size: 60px;
  }
}
  
//Line Height
.line-height {
  &-10 {
    line-height: 10px;
  }
  &-11 {
    line-height: 11px;
  }
  &-12 {
    line-height: 12px;
  }
  &-13 {
    line-height: 13px;
  }
  &-14 {
    line-height: 14px;
  }
  &-15 {
    line-height: 15px;
  }
  &-16 {
    line-height: 16px;
  }
  &-17 {
    line-height: 17px;
  }
  &-18 {
    line-height: 18px;
  }
  &-19 {
    line-height: 19px;
  }
  &-20 {
    line-height: 20px;
  }
  &-21 {
    line-height: 21px;
  }
  &-24 {
    line-height: 24px;
  }
  &-32 {
    line-height: 32px;
  }
  &-38 {
    line-height: 38px;
  }
  &-40 {
    line-height: 40px;
  }
  &-45 {
    line-height: 45px;
  }
  &-48 {
    line-height: 48px;
  }
  &-50 {
    line-height: 50px;
  }
  &-55 {
    line-height: 55px;
  }
  &-58 {
    line-height: 58px;
  }
  &-60 {
    line-height: 60px;
  }
  &-66 {
    line-height: 66px;
  }
}

//Old line height - not use
.text-line-height {
  &-20 {
    line-height: 20px;
  }
  &-22 {
    line-height: 22px;
  }
  &-24 {
    line-height: 24px;
  }
}

//Max width fc
.mw-fc {
  max-width: fit-content;
}
//Max width
.mw-25px {
  max-width: 25px;
}
.mw-70px {
  max-width: 70px;
}
.mw-125px {
  max-width: 125px;
}
.mw-100px {
  max-width: 100px;
}

//font-family
.font-family {
  &-roboto {
    font-family: 'Roboto', sans-serif;
  }
  &-montserrat {
    font-family: 'Montserrat', sans-serif;
  }
  &-brushscript {
    font-family: BrushScript, BrushScript2, 'Brush Script MT', sans-serif;
  }
}

.unselectable {
  user-select: none;
}