@import "../../config/constant.scss";

.rb-dropdown, .rb-dropup {
  border-radius: 2px;
  line-height: 26px;
  padding-right: 32px;
  font-size: 14px;
  text-align: left;
  background: $rb-white;
  border: 1px solid $rb-light-grey;
  color: $rb-black;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.dp-focus {
    border-color: $rb-dark-grey;
  }
  &.opacity {
    color: $rb-grey;
  }
  &.is-invalid {
    border-color: $red;
  }
  &-group {
    width: 100%;
    .dropdown-menu {
      margin-top: 0;
      border-top: none;
      border-radius: 0 0 2px 2px;
      width: 100%;
      transform: translateY(39px)!important;
      padding: 1px 0;
      border-color: $rb-dark-grey;
      min-width: initial;
      .dropdown-item {
        padding: 0.75rem;
        font-size: 14px;
        cursor: pointer;
      }
      &.show {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }
  &.dropdown-toggle::after {
    color: $rb-black;
    position: absolute;
    right: 20px;
    top: 45%;
  }
  &:hover {
    background: $rb-white;;
    border-color: $rb-dark-grey;
  }
  &:focus {
    background: $rb-white;;
    outline: none;
    box-shadow: none;
    border-color: $rb-dark-grey;
    &::after {
      border-bottom: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-top: 0;
      border-left: 0.3em solid transparent;
    }
  }
}

.rb-dropup {
  &-group {
    .dropdown-menu {
      margin-bottom: 0;
      border-top: solid 1px $rb-dark-grey;
      border-bottom: none;
      border-radius: 2px 2px 0 0;
      width: 100%;
      transform: translateY(-39px)!important;
      top: initial!important;
      bottom: 0;
      padding: 1px 0;
      border-color: $rb-dark-grey;
      min-width: initial;
      .dropdown-item {
        padding: 0.75rem;
        font-size: 14px;
        cursor: pointer;
      }
      &.show {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }
}

.rb-select {
  border-color: $rb-light-grey!important;
  font-size: 14px!important;
  color: $rb-black!important;
  &.is-invalid {
    .rb-select__control {
      border-color: $red!important;
    }
  }
  &__control{
    font-size: 14px!important;
    height: 40px;
    border-radius: 2px!important;
    border-color: $rb-light-grey!important;
    &--is-focused {
      box-shadow: none!important;
      border-color: $rb-dark-grey!important;
    }
    .rb-select__dropdown-indicator {
      padding: 0!important;
      margin-right: 20px;
      border-top: 0.3em solid $rb-black;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      &:hover {
        border-top: 0.3em solid $rb-black;
      }
      svg {
        display: none;
      }
    }
    &--menu-is-open {
      box-shadow: none!important;
      border-color: $rb-dark-grey!important;
      .rb-select__dropdown-indicator {
        padding: 0!important;
        margin-right: 20px;
        border-bottom: 0.3em solid $rb-black;
        border-right: 0.3em solid transparent;
        border-top: 0;
        border-left: 0.3em solid transparent;
        &:hover {
          border-top: 0.3em solid $rb-black;
        }
        svg {
          display: none;
        }
      }
    }
  }
  &__menu {
    font-size: 14px!important;
    margin: 0!important;
    border-radius: 0 0 2px 2px!important;
    border: 1px solid $rb-dark-grey!important;
    border-top: none!important;
    box-shadow: none!important;
    transform: translateY(-1px);
  }
  &__indicator-separator {
    display: none;
  }
  &__option {
    &--is-selected, &--is-focused {
      color: $rb-black!important;
      background-color: #f8f9fa!important;
    }
  }
}

.rb-select.isMulti {
  .rb-select__control {
    height: initial;
  }
}

.dd-arrow {
  right: 15px;
  top: 12px;
}