@import "../../config/constant.scss";
@import '../../config/devices.scss';

.card-rb {
  background: $rb-white;
  border-radius: 5px;
  // box-shadow: 0 1px 1px 0 $rb-light-shadow;
  &.orange {
    background-color: $rb-yellow !important;
    * {
      color: $rb-white !important;
      border-color: white;
    }
    button {
      background: inherit !important;
    }
  }
  &-header {
    border-bottom: 1px solid $rb-lighter-grey;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding: 0 20px!important;
    height: 63px;
    .link {
      cursor: pointer;
      height: 63px;
      font-size: 12px;
      color: $rb-darker-grey;
      .link-text {
        transform: translateY(-50%);
        top: 50%;
      }
      span.active {
        border-bottom: 2px solid #006DBA;
        width: 100%;
        height: 63px;
        display: block;
        position: absolute;
        top: 0px;
      }
      &.size-1 {
        max-width: 60px;
      }
      &.size-2 {
        max-width: 90px;
      }
      &.size-3 {
        max-width: 100px;
      }
      &.size-4 {
        max-width: 130px;
      }
      &.size-5 {
        max-width: 160px;
      }
      &.size-25 {
        max-width: 25%;
      }
      &.size-50 {
        max-width: 50%;
      }
      &.size-75 {
        max-width: 75%;
      }
      &.size-100 {
        max-width: 100%;
      }
    }
  }

  & > .row.pt-0:last-of-type{
    position: absolute !important; 
    bottom: 20px !important;
    width: 97%;
  }

  & > .row {
    padding: 16px 20px 16px 20px;
    &.mobile {
      padding: 17px;
    }
    .text-right{
      color: #202020;
      text-transform: capitalize;
    }
    button {
      display: block;
      margin-right: auto;
      margin-left: auto;
      height: 40px;
      min-width: 120px;

      @include respond-below(sm){
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
}
