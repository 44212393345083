@import '../../config/constant.scss';

.insights-total-rectangle {
    background-color:$rb-white;
    width: 720px;
    height: 180px;
    text-align: center;
}

.insights-line-top {
    border-top: solid 1px $rb-lighter-grey;
}

.insights-line-bottom {
    border-bottom: solid 1px $rb-lighter-grey;
}

.insights-line-right {
    border-right: solid 1px $rb-lighter-grey;
}

.insights-box {
    border: solid 1px $rb-lighter-grey;
}

.insights-text-period {
    color: $rb-grey-text;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 12px;
}

.insights-text-annualized {
    color: $rb-black;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    opacity: 0.5;
}

.insights-highlights-text {
    color: $rb-black;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.insights-graph-title {
    color: $rb-black;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.insights-graph-text {
    color: $rb-grey-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.insights-show-more {
    color: $primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.insights-actived-text {
    color: $primary !important;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.42px;
    line-height: 12px;
}

.insights-inactive-tab {
    color: #76777B !important;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.42px;
    line-height: 12px;
}

.insights-actived-line {
    border: solid $primary;
    border-width: 0 0 2px 0;
    color:$rb-black;
    opacity: 1;
}

.insights-actived-line-top {
    border: solid $rb-blue;
    border-width: 2px 0 0 0;
    color:$rb-black;
    opacity: 1;
}

.insights-header-img {
    overflow: hidden;
    text-align: center;
    justify-content: center;
    position: relative;
    background-color: $rb-white;
    .insights-row {
      width: 100%;
      position: absolute;
      margin: 0;
      text-align: center;
      justify-content: center;
      top: 0;
      left: 0;
      .insights-title {
        font-size: 35px;
        padding-top: 40px;
        color: $rb-white;
        font-weight: 700;
        line-height: 40px;
      }
    }
}

.insights-empty-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.insights-empty-text2 {
    color: $rb-grey-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.scrollbar-insights::-webkit-scrollbar {
	width: 8px;
	background-color:$rb-white;
}

.scrollbar-insights::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color:$rb-white;
}

.scrollbar-insights::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $rb-lighter-grey
}
.blue-bg {
    background-color: $rb-dark-blue;
}
.yellow-bg {
    background-color: $rb-yellow;
}
.text-yellow {
    color: $rb-yellow;
}
.bg-striped-title {
    background-color: #F5F5F5;
    color: #76777B;
    font-size: 14px;
}
.bg-striped {
    background-color: #F5F5F5;
}

.bg-inside-track {
  background-image: url('https://rebate-portal.s3.amazonaws.com/resources/insights/images/bg-inside-track.svg');
  background-repeat: no-repeat;
  background-size:cover;
  height: 420px;
}

#locationDropdown, #contractsDropdown {
    margin-left: -15px;
}

.location-dropdown-col {
    padding-right: 20px;
}

.tabs-col {
    padding-right: 20px;
}

.overall-stats-figures {
    font-family: $lightFont;
}

.table-font-size {
    font-size: 14px;
}

.annual-rebate-justify {
    justify-content: space-between;
}

.insights-data-line-spacing {
    margin: 20px 0px;
}
