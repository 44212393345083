@import '../../config/constant.scss';
@import '../../config/devices.scss';

.container-reset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

.left-reset {
    font-family: $lightFont, sans-serif;
    display: flex;
    flex-direction: column;
    width:385px;
    background: url('../../assets/images/bg-image-2.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    color: $rb-white;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;

    @include respond-below(sm) {
        display: none;
    }

    .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30%;
        margin-bottom: 15%;
    }
    .bullets{
        padding: 0 10% 15% 10% !important;
    }
    
}

.right-reset {    
    display: flex;
    width:70vw;
    height: 100vh;
    flex-direction: column;

    @include respond-below(sm) {
        width:100vw;
    }

    .header-reset{
        display: flex;        
        width: 100%;        
        border: 1px solid $rb-light-grey;
        
        @include respond-below(sm) {
            align-items: center;
            justify-content: center;
            height: 4.5em;
        }

        .title{
            font-size: 1em;
            font-weight: 300;

            @include respond-below(sm) {
                font-size: 1.5em;
                text-align: center;
            }
        }
    }

    .reset-form{
        display: flex;
        width: 400px;
        margin: 120px 25% 10% 10%;

        @include respond-below(sm) {
            width: 90%;
            margin: 5% 5% 15% 5% !important;
        }

        .title{
            width: 100%;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 40px; 

            @include respond-below(sm) {
                font-size: 30px;
                text-align: center;
            }
        }

        .login-text{
            height: 50px;
        }

        ::placeholder{
            opacity: 50%;
        }

        .forgotpass-text{
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            line-height: 20px;
            color: $rb-darker-grey;
        }

        .forgot-text{
            display: inline-block;
            margin-bottom: 0.5rem;
            color: $primary;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
        }

        .link-style{
            color: $primary;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
        }

        .line-labels{
            display: flex;
            justify-content: space-between;
        }

        .form-group{
            margin-bottom: 40px;
            a{
                color: 0071ce;
            }
        }

    }

    .footer-reset{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0% 25% 0% 10%;
        @include respond-below(sm) {
            margin: 0% 10% 0% 10%;
            justify-content: center;
        }

    }
    
}

