@import "../../config/constant.scss";

.rb-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
}

.rb-modal-header {
  font-size: 22px;
  padding: 40px 45px 5px;
  font-weight: 300;
  border: none;
  &.blue-header {
    background: $primary;
    color: $rb-white;
    div {
      font-weight: lighter;
    }
  }
  .rb-close {
    padding: 0;
    position: absolute;
    top: 29px;
    right: 37px;
    &:focus {
      outline: none;
    }
  }
  &.mobile {
    padding: 22px 15px;
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid $rb-light-grey;
    div {
      width: 100%;
    }
    .rb-close {
      top: 34px;
      right: 34px;
    }
  }
}

.rb-modal-body {
  width: 100%;
  padding: 25px 45px;
  &.mobile {
    padding: 15px;
  }
}

.rb-modal-footer {
  width: 100%;
  padding: 0 45px 40px 45px;
  border: none;
  &.mobile {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}

.modal {
  &-sm {
    max-width: 400px;
  }
  &-md {
    max-width: 500px;
  }
  &-lg {
    max-width: 700px;
  }
  &-xl {
    max-width: 1000px;
  }
  &-100 {
    max-width: 100%;
  }
}

.modal-dialog {
  margin-top: 93px;
  &.mobile {
    width: 100%;
    position: absolute;
    left: 0;
    margin: 0;
    bottom: 0;
    overflow-y: scroll;
    max-height: 100vh;
    .modal-content {
      border-radius: 0;
      margin-top: 0px;
      max-height: 100vh;
    }
    .rb-modal-body {
      max-height: calc(100% - 70px);
      overflow-y: auto;
    }
  }
}

.rb-modal-dialog-centered {
  display: flex;
  margin: 0 auto!important;
  align-items: center;
}

.modal.fade-down .modal-dialog{
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, 25%);
  -ms-transform: translate(0, 25%);
  transform: translate(0, 25%);
}
.modal.show .modal-dialog{
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.rb-modal-header.insights-opportunities-modal-header {
  padding: 0;
  .col.orginal {
    background-color: $primary;
    font-size: 14px;
    color: white;
  }
  .col.match {
    background-color: $secondary;
    font-size: 14px;
    color: white;
  }
}