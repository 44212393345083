@import '../../config/constant.scss';

.foodbam-home {
  .hero-row {
    padding-top: 120px;
    padding-bottom: 120px;
    color: #ffffff;
    background: url('../../assets/images/foodbam/rebate-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      font-family: $bodyFont;
      font-size: 50px;
      font-weight: 700;
      line-height: 60px;
      margin-bottom: 30px;
    }

    p {
      font-family: $bodyFont;
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      margin-bottom: 35px;
    }

    em {
      color: #ff9110;
      font-style: normal;
    }

    button {
      min-height: 60px;
    }
  }

  .odd-row {
    padding-top: 100px;
    padding-bottom: 100px;
    background:#f8f8f8;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    h2 {
      color: #202020;
      font-family: $bodyFont;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      text-align: left;
      margin-bottom: 20px;
    }

    p {
      color: #76777B;
      font-family: $bodyFont;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
    }
  }

  .even-row {
    padding-top: 100px;
    padding-bottom: 100px;
    background:#ffffff;

    h2 {
      color: #202020;
      font-family: $bodyFont;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      text-align: left;
      margin-bottom: 20px;
    }

    p {
      color: #76777B;
      font-family: $bodyFont;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
    }
  }

  .cta-row {
    color: #ffffff;
    padding-top: 80px;
    padding-bottom: 80px;
    background:#0072ef;

    h2 {
      font-family: $bodyFont;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    p {
      font-family: $bodyFont;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }
  }
}
