@import '../../config/constant.scss';
@import '../../config/devices.scss';

.home-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height:100vh;
  .home-content {
    flex: 1;
  }
}

.home-header {
  background-color: $rb-dark-blue;
  height: 100%;
  color: $rb-white;
  text-align: center;  
  .logo {
    padding-left: 30px;
    padding-right: 30px;
    img {
      width: 100%;
    }
  }
}

.home-content {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: $rb-white;  
  text-align: center;
  align-items: center;

  @include respond-below(xs) {
    background-position: 30% 5%;    
    height: 145vh;
  }
  @include respond-between(xs,sm) {
    background-position: 30% 5%;    
    height: 130vh;
  }  
}

.home-bg {
  background: url('../../assets/images/rebate-bg-light.png');  
  background-repeat: no-repeat;
  background-size: cover;
}

.login-box {
  background-color: fade-out($color: #000000, $amount: 0.5);
}

.home-row-title{
  font-size: 65px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 1px;
  padding: 11% 20% 7% 20%;

  @include respond-below(xs) {
    font-size: 20px;    
    line-height: 40px;
    padding: 10% 10% 18% 10%;
  }
  @include respond-between(xs,sm) {
    font-size: 45px;
    line-height: 80px;
    padding: 25% 5% 10% 5%;
  }
}

.home-row-title-2 {
  font-size: 40px;
  text-align: left;
  line-height: 46.88px;
}

.savings-bg {
  background-color: #EFF0F3;
  color: #23408F;
}

.calc-slider {
  .thumb {
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(35, 64, 143, 0.26);
    width: 20px;
    height: 20px;
    margin-top: 5px;
    &:focus {
      outline: none;
    }
  }
  .track {
    background-color: #CAD1E4;
    border-radius: 5px;
    height: 4px;
    margin: 13px 0;
  }
}

.calc-dropdown {
  .dropdown-item {
    line-height: 40px;
    color: #23408F;
  }
  .calc-dropdown-button {
    height: 40px;
  }
  .calc-dropdown-button, .calc-dropdown-menu {
    background-color: white;
    border: 1px solid #C9D3EF;
    border-radius: 2px;
    color: #23408F;
    text-align: left;
    padding-left: 16px;
    &::after{
      position: absolute;
      top: 18px;
      right: 16px;
    }
    &:focus {
      outline: none;
    }
  }
  .calc-dropdown-menu {
    padding: 0;
  }
}

.calc-button {
  background-color: white;
  border: 1px solid #C9D3EF;
  border-radius: 2px;
  color: #23408F;
  text-align: center;
  height: 40px;
  &.active {
    background-color: #23408E;
    border: 1px solid #C9D3EF;
    color: #FFFFFF;
  }
  &:focus {
    outline: none;
  }
}

.calc-checkbox {
  background-color: #FFFFFF;
  border: 1px solid #C9D3EF;
  max-width: 20px;
  height: 20px;
  padding: 0;
  &.active {
    background-color: #23408E;
    border: none;
  }
  svg {
    position: absolute;
    top: 2px;
    left: 2px;
  }
}

.calc-result {
  color: #F88903;
  font-size: 69px;
  line-height: 80.86px;
  text-align: left;
}

.annually {
  color: #F88903;
  font-family: $bodyFont;
  font-size: 20px;
  line-height: 23.44px;
  text-align: center;
}

.bg-blue-home {
  background: #23408F;
}

.by-enrolling-in-my-d {
  color: #23408F;
  font-family: $bodyFont;
  font-size: 20px;
  line-height: 23.44px;
  text-align: center;
}

.calc-started-button {
  color: white;
  background-color: #23408E;
  border-radius: 2px;
  width: 170px;
  height: 50px;
}

.calc-again-button {
  background-color: transparent;
  color: #23408E;
  border-radius: 2px;
  width: 170px;
  height: 50px;
}

.calc-input {
  background-color: #FFFFFF!important;
  border: 1px solid #C9D3EF!important;
  border-radius: 2px!important;
  height: 40px!important;
  color: #23408F!important;
  &.is-invalid {
    border-color: #dc3545!important;
  }
}

.calc-calculate {
  background-color: #F88903;
  border: none;
  border-radius: 2px;
  width: 170px;
  height: 50px;
  color: #FFFFFF;
}

.calc-search {
  border: none;
  color: #23408F;
  &:focus {
    outline: none;
  }
}

.home-title-works {
  font-size: 40px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 1px;
}

.home-row-center{
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: space-between;
  padding: 5% 25% 0% 25%;
  height:10%;

  @include respond-below(sm) {
    flex-wrap:wrap;
    justify-content: center;    
    height: 150px;
    padding: 0% 10% 0% 10%;
    margin-top: 15%;
  }

  @include respond-between(xs,sm) {
    flex-wrap:wrap;
    justify-content: center;
    height: 300px;
    padding: 0% 15% 0% 15%;
    margin-top: 15%;
  }
}

.home-row-bottom{  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 5% 5% 5%;
}

.home-row-text{
  color: $rb-white;
}

.clearfix::after{
  // overflow: auto;
  content: "";
  clear: both;
  display: table;
}
