@import "../../config/constant.scss";

$size: 24px;

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding-left: 34px;
    margin: 20px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    &.invalid {
        .checkmark {
            border-color: $red!important;
        }
    }
}
  
/* Hide the browser's default checkbox */
.checkbox-container 

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $size;
    width: $size;
    background-color: $rb-white;
    border-radius: 2px;
    border: 1px solid $rb-light-grey;
}
  
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: $rb-light-grey;
}
  
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: $primary;
    border-color: $primary;
    &.be-blue {
        background-color: $primary;
        border-color: $primary;
    }
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
  
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 6px;
    width: 4px;
    height: 9px;
    border: solid $rb-white;    
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

