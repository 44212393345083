@import "../../config/constant.scss";

$size: 24px;

/* Customize the label (the container) */
.radiobutton-container {
    display: block;
    position: relative;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding-left: 34px;
    margin: 20px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    &.invalid {
        .mark {
            border-color: $red!important;
        }
    }
}
  
/* Hide the browser's default RadioButton */
.radiobutton-container 

/* Create a custom RadioButton */
.mark {
    position: absolute;
    top: 0;
    left: 0;
    height: $size;
    width: $size;
    background-color: $rb-white;
    border-radius: 50%;
    border: 1px solid $rb-light-grey;
}
  
/* On mouse-over, add a grey background color */
.radiobutton-container:hover input ~ .mark {
    background-color: $rb-light-grey;
}
  
/* When the RadioButton is checked, add a blue background */
.radiobutton-container input:checked ~ .mark {
    background-color: $primary;
    border-color: $primary;
}
  
/* Create the mark/indicator (hidden when not checked) */
.mark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the mark when checked */
.radiobutton-container input:checked ~ .mark:after {
    display: block;
}
  
/* Style the mark/indicator */
.radiobutton-container .mark:after {
    left: 6px;
    top: 6px;
    width: 10px;
    height: 10px;

    background-color: $rb-white;
    border-radius: 50%;
    border: 1px solid $rb-white;
}

