
.foodbam-app {

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  iframe#foodBamFrame {
    width: 100%;
    min-height: 85vh;
  }

}
