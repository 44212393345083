.progress-inv {
	border-radius: 20px;
	margin: 5px 0;
	height: 16px;
	width: 100%;
}

.progress-inv-done {
	background: linear-gradient(to left, #49A4DA, #40A1DA);
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}