@import '../../config/constant.scss';

.cutting-edge {
  color: $rb-yellow;
  font-size: 24px;
  font-weight: 500;
}

.cutting-edge-mobile {
  color: $rb-yellow;
  font-size: 18px;
  // font-weight: 500;
}

.prime-our-beyond {
  color: $rb-black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 860px;
}

.bounds {
  background-image: linear-gradient(90deg, $rb-blue 0%, $rb-blue 100%);
  border: 1px solid $rb-white;
  margin-top: 1px;
  line-height: 320px;
  text-align: center;
  img {
    width: 80%;
    height: auto;
  }
}

.img-ad {
  border: 2px solid $rb-white;
  text-align: center;
}

.corner-cut {
  background-color: $rb-dark-blue;
  height: 30px;
  line-height: 30px;
  display: inline-block;
}

.corner-cut::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-top: 60px solid $rb-dark-blue;
  border-right: 70px solid transparent;
  //border-left: 20vw solid $rb-dark-blue;
}

.bounds-mobile {
  background-image: linear-gradient(90deg, $rb-blue 0%, $rb-blue 100%);
  border: 1px solid $rb-white;
  margin-top: 1px;
  height: 380px;
  text-align: center;
}

.corner-cut-mobile {
  height: 30px;
  line-height: 30px;
  display:inline-block;
}

.corner-cut-mobile::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-top: 40px solid;
  border-top-color: inherit;
  border-right: 45px solid transparent;
  //@include: 81vw solid $rb-dark-blue;
}

.bounds:hover .overlay-prime, .overlay-prime {
  opacity: 1;
}

.prime-card-main-footer {
  position: absolute;
  // color: $rb-yellow !important; 
  top: 90%;
  right: 20px;
  font-family: $bodyFont;
  // font-style: italic;
  font-weight: 900;
  line-height: 19px;
}

.prime-card-hover-header {
  top: 20%;
  left: 0;
  right: 0;
  color: $rb-blue-sec;
  font-family: $bodyFont;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.prime-card-hover-footer {
  position: absolute;
  top: 20%;
  right: 20px;
  font-family: $bodyFont;
  font-weight: 900;
  line-height: 19px;
}

.overlay-prime {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: .5s ease;
  background-color: $secondary;
}

.card-main-header {
  position: absolute;
  top: -40%;
  left: 0;
  color: $rb-white;
}

.content-more-programs {
  height: 300px;
  border-top: 1px solid;
  border-color: $rb-white;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-more-pograms {
  // background-color:$rb-white;
  border-radius: 2px;
  border: none;
  width: 200px;
  height: 60px;
}

.btn-prime-learn {
  border: 1px solid $rb-white !important;
  border-radius: 2px !important;
  width: 120px;
  height: 40px;
  // color: $rb-white; 
  // background-color: $rb-yellow;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

.btn-prime-signup {
  border: 1px solid $rb-white !important;
  border-radius: 2px !important;
  width: 120px;
  height: 40px;
  // color: $rb-yellow;
  // background-color: $rb-white; 
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

.btn-prime-learn-mobile {
  border: 1px solid $rb-white;
  border-radius: 2px;
  width: 120px;
  height: 40px;
  // color: $rb-white; 
  // background-color: $rb-blue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

.btn-prime-signup-mobile {
  border: 1px solid $rb-white;
  border-radius: 2px;
  width: 120px;
  height: 40px;
  // color:  $rb-blue;
  // background-color: $rb-white; 
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

.text-prime {
  color: $rb-white;
  font-size: 13px;
  word-wrap : break-word !important;
  line-height: 20px;
}

.prime-signup-text1 {
  color: $rb-black;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.prime-signup-text2 {
  color: $rb-black;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
}

#prime-header-img {
    overflow: hidden;
    text-align: center;
    justify-content: center;
    position: relative;
    background-color: $rb-white;
    .prime-row {
      width: 100%;
      position: absolute;
      margin: 0;
      text-align: center;
      justify-content: center;
      top: 0;
      left: 0;
      padding-top: 0;
      height: 222px;
      .prime-title {
        padding-top: 40px;
        color: $rb-white;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 40px;
      }
      .prime-label {
        color: $rb-white;
        font-size: 16px;
        font-weight: 400;
        max-width: 1440px;
        padding: 0 20%;
      }
    }
  }