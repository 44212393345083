@import "../../config/constant.scss";

.dashed-box-grey {
    color: $rb-darker-grey;
    background: linear-gradient(to right, #D7D8D9 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#D7D8D9 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #D7D8D9 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#D7D8D9 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 20px 1.5px, 1.5px 20px;
    &.text-red {
        color: $red;
    }
}