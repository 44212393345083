@import '../../config/constant.scss';

.sb-custom-overlay {
  z-index: 21;
}
  
.slide-pane__content {
  padding: 0 !important;
  overflow-x: hidden;
}

.notification-icon {
  padding: 0.2em 0.5em;
  font-size: 11px;
  .notification-count {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    right: 10px;
    font-weight: bold;
    background-color: $rb-yellow-light;
    border-radius: 45%;
    height: 18px;
    width: 18px;
  }
}