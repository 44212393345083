@import '../../config/devices.scss';
@import '../../config/constant.scss';

.foodbam-add-distributors {
  h1 {
    color: #202020;
    font-family: $bodyFont;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }

  .steps{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include respond-below(sm) {
      max-width: 100%;
      padding: 0% 10% 0% 10%;
      justify-content: space-between;
    }
  }

  p.enter-email {
    color: #202020;
    font-family: $bodyFont;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
  }

  svg.icon-marker {
    margin-top: -5px;
    margin-right: 10px;
  }

  p.merchant-nickname {
    color: #202020;
    font-family: $bodyFont;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .border {
     border: 1px solid #d8d8d8;
  }
}
