@import '../../config/constant.scss';

.claim-text1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
}

.claim-text2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.claim-header-img {
    background-color: $rb-white;
    .claim-row {
      width: 100%;
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      justify-content: center;
      .claim-title {
        font-size: 35px;
        padding-top: 40px;
        color: $rb-white;
        font-weight: 700;
        line-height: 40px;
      }
    }
}

.claim-steps {
  .steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.claim-gray {
  background-color: $rb-grey-new;
}

.claim-authorized-div {
  border: 1px solid $rb-light-grey;
  padding: 2px 15px;
  font-size: 10px;
  line-height: 14px;
}

.claim-input-birth {
  height: 38px;
  width: 100%; 
  font-size: 14px; 
  padding: 10px;
}

input[type='text']:disabled {
  background: $rb-grey-new;
  color: $rb-grey
}

input[type='number']:disabled {
  background: $rb-grey-new;
  color: $rb-grey
}