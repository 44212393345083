@import '../../config/devices.scss';
@import '../../config/constant.scss';

.foodbam-payment-details {
  min-height: 80vh;

  label {
    display: inline;
  }

  h1 {
    color: #202020;
    font-family: $bodyFont;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }

  .steps{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include respond-below(sm) {
      max-width: 100%;
      padding: 0% 10% 0% 10%;
      justify-content: space-between;
    }
  }

  .border-left {
    border-left: 1px solid #d8d8d8;
  }

  p.enter-payment-detail {
    color: #202020;
    font-family: $bodyFont;
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
    text-align: left;
  }

  h3 {
    color: #202020;
    font-family: $bodyFont;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    em {
      color: #ff9110;
      font-style: normal;
    }
  }

  p {
    color: #76777B;
    font-family: $bodyFont;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
  }

  .line-items {
    margin-bottom: 5%;
    .bullet-circle-container {
      margin-right: 3%;
    }
    .line-text {
      color: #76777B;
      font-family: $bodyFont;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
    }
  }

  .fb-input-large, .StripeElement{
    border: solid 1px #d8d8d8 !important;
    width:100%;
    background: #fff;
    display: block !important;
    padding:15px;
    font-size: 16px !important;
    color: #143357!important;
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    &::-webkit-input-placeholder {
      color: #d7d8d9;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #d7d8d9;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
      color: #d7d8d9;
    }
    &:-ms-input-placeholder {
      color: #d7d8d9;
    }
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

}
