@import '../../config/constant.scss';

.step {
    display: flex;
    align-items: center;    
    justify-content: center;
    border-radius: 50%;    
    height: 2.2em;
    width: 2.2em;
    text-align: center;
    border: 1px solid $rb-darker-grey;
}

.step-inactive {
    background-color: $rb-white;
    color: $rb-darker-grey;    
    border-color: $rb-darker-grey;
}

.step-active{
    background-color: $primary;
    color: $rb-white;
    border-color: $primary;
}

.step-done{
    background-color: $primary;
    color: $rb-white;
    border-color: $primary;
}