#item-intel-container, .table-container {
  .filters {
    &-container {
      height: calc(100vh - 114px);
      overflow-y: auto;

      &-mobile {
        height: calc(100vh - 114px - 53px);
        overflow-y: auto;
      }
    }

    &-title {
      // margin-bottom: 20px;
      font-size: 1.2rem;
      font-weight: 800;

      &-mobile {
        width: 100%;
        position: fixed;
        left: 15px;
        font-size: 1.1rem;
        font-weight: 500;
        justify-content: center;
        background-color: #ffffff;
        z-index: 101;
      }
    }

    &-group-title {
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    &-options-container {
      max-height: 140px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 0;
    }

    &-options-group {
      padding-top: 5px;
      padding-bottom: 5px;
      // margin-left: 0;
      display: flex;
      align-items: center;
      .checkbox-container {
        margin: 0;
      }
      .col:nth-child(2) {
        padding-left: 0;
        margin-left: 12px;
      }
    }
  }

  .close-icon {
    position: absolute;
    right: 20px;
  }

  .special-margin-top {
    margin-top: 70px;
  }

  .buttons-container {
    width: 100%;
    position: fixed;
    bottom: 53px;
    left: 15px;
    background-color: #ffffff;
    z-index: 101;
  }

  .items-list-rows {
    &-headers {
      padding-right: 23px;
    }
    &-container {
      max-height: calc(100vh - 116px - 50px - 50px - 50px);
      padding-right: 15px;
      margin-bottom: 15px;
      overflow-y: auto;
      // scroll-behavior: smooth;
    }
    &-container-mobile {
      // position: fixed;
      // left: 0;
      // padding-left: 10px;
      // // padding-right: 10px;
      // background-color: #ffffff;
      // z-index: 101;
      max-height: calc(100vh - 56px - 120px - 53px);
      overflow-y: auto;
      // scroll-behavior: smooth;

      .card-rb-size {
        padding-left: 0;
        padding-right: 10px;
      }
    }
  }
}

#item-intel-details-container {
  .details-title,
  .details-info-title {
    font-weight: bold;
  }

  .details-section-title,
  .similar-item-card-title {
    font-weight: bold;
  }

  .details-card-container {
    margin-left: -30px;

    &-mobile {
      margin-bottom: 70px;
    }
  }

  .go-back-icon {
    position: absolute;
    left: 20px;
  }
}
