@import "../../../config/constant.scss";


#locationDropdown{
    button{
        border: none;
    }
}

#distributorDropdown{
    button{
        border: none;
    }
}

.locationDistributorSearch{
    input{
        border: none;
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: $primary;
    border-color: transparent;
}
.custom-control-input:checked~.custom-control-label::before {
    color: $rb-white;
    border-color: transparent;
    background-color: $primary;
}