@import "../../config/constant.scss";

#BrowserExtensionBanner {
   div {
     color: #fff;
     background-color: #0071ce;
     padding-top: 10px;
     padding-bottom: 10px;
     height: 80px;

     h1 {
       font-family: $bodyFont;
       font-size: 20px;
       font-weight: bold;
       line-height: 17px;
       margin-top: 10px;
     }

     p {
       font-family: $bodyFont;
       font-size: 14px;
       font-weight: normal;
       line-height: 24px;
       opacity: 0.8;
     }

     &#logo-wrapper {
       text-align: center;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      img {
        max-width: 100%;
        max-height: 40px;
      }

      .border-right {
        padding: 0 15px 0 0;
        border-right: 1px solid #78b4e5 !important;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
     }

     &#btn-wrapper {
       -webkit-border-top-right-radius: 5px;
       -webkit-border-bottom-right-radius: 5px;
       -moz-border-radius-topright: 5px;
       -moz-border-radius-bottomright: 5px;
       border-top-right-radius: 5px;
       border-bottom-right-radius: 5px;

       button {
         margin-top: 10px;
         height: 40px;
         position: absolute;
         right: 32px;
       }
     }
   }
}
