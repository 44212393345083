@import '../../config/constant.scss';

.tab-list {
    border-bottom: 1px solid $rb-lighter-grey;
    padding-left: 0;
  }

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 2rem 1rem;
  color:$rb-black;
  opacity: 0.5;
}
  
.tab-list-active {
    background-color: $rb-white;
    border: solid $primary;
    border-width: 0 0 2px 0;
    color:$rb-black;
    opacity: 1;
  }