@import '../../config/constant.scss';

.line-items{
    display: flex;
    align-items: center;
    margin-bottom: 10%;
    .bullet-circle-container{
        margin-right: 8%;
    }
    .bullet-circle {
        display: flex;
        align-items: center;    
        justify-content: center;
        background-color: $primary;
        border-radius: 50%;
        color: $rb-white;
        height: 30px;
        width: 30px;        
        text-align: center;        
    }
    .line-text{
        font-size: 16px;
        text-align: left;
        font-weight: 400;
        line-height: 25px;
    }

    .bullet-circle-big {
        display: flex;
        align-items: center;    
        justify-content: center;
        background-color: $rb-blue;
        border-radius: 50%;
        color: $rb-white;
        height: 70px;
        width: 70px;        
        text-align: center;        
    }
}
