
@import '../../config/constant.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.faq-title {
  color: $rb-black;
  font-size: 24px;
  line-height: 28px;
}

.faq-title-card {
  color: $rb-black;
  font-size: 18px;
  line-height: 23px;
}

.faq-question-text {
  color: $rb-yellow;
  font-size: 16px;
  line-height: 18.75px;
}

.faq-answer-text {
  color: $rb-grey-text;
  font-size: 14px;
  line-height: 20px;
}

.faq-mask-youtube {
  position: absolute;
  background: rgba(0,0,0,0);
  width: 100%;
  height: 250px;
  top: 0;
  left: 0;
}

.modal-faq-mobile{
  margin: 0 5%;
}