@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

.join-today-container {
	.rb-select, .rb-select__control {
		border-radius: 0!important;
		flex: 1
	}

	.bg-join-signup {
		background-image: url('../../assets/images/become-a-member-2.png');
		background-size: cover;
	}

	.bg-join-earn {
		background-image: url('../../assets/images/earn-money.png');
		background-size: cover;
	}

	.mw-800 {
		max-width: 800px;
	}

	.mw-1140 {
		max-width: 1140px;
	}

	.mw-1600 {
		max-width: 1600px;
	}

	.font-montserrat {
		font-family: "Montserrat",Sans-serif;
		font-size: 16px;
		line-height: 24px
	}

	.text-title {
		color: #fff;
		font-size: 40px;
		font-weight: 700;
		line-height: 52px;
	}

	.text-signup-title {
		color: #fff;
		font-size: 23px;
		font-weight: 700;
		line-height: 29.9px;
	}

	.text-small-title {
		color: theme-color('action');
		font-size: 22px;
		font-weight: 700;
		line-height: 26.4px;
	}

	.text-gradient-title {
		color: color('white');
		font-size: 50px;
		font-weight: 300;
		line-height: 1.1em;
	}

	.text-title-numbers {
		font-size: 60px;
		font-weight: 700;
		line-height: 1
	}

	.btn-action, .btn-warning {
		font-family: "Montserrat",Sans-serif;
		font-weight: 700;
		font-size: 16px;
		padding: 15px 30px;
		border-radius: 0!important;
		color: color('white')!important;
	}

	.join-benefits-image {
		max-width: 53px;
		filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
		opacity: .7;
		transition-duration: .5s;
	}

	.join-benefits-image-div:hover .join-benefits-image {
		filter: brightness(100%) contrast(120%) saturate(100%) blur(0px) hue-rotate(0deg);
		opacity: 1;
	}

	.owl-carousel .owl-item img {
		width: 100%!important;
	}

	.border-signup {
		border: 4px solid theme_color('warning')!important;
	}

	input.form-control {
		border-radius: 0;
	}

	.form-group label {
		margin-top: 15px;
	}

	.video-container {
		width: 100%;
		video{
			width: 100%;
			max-width: 100%;
			margin: 0;
			object-fit: cover;
		}
	}
}
