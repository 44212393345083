@import './combinedScss.scss';
// Constant colors
$rb-light-aqua: #F8F8F8;             // Body Background

$rb-aqua: #eef3f6;                   // Header Background
$rb-dark-aqua: #dce8f2;             // For borders
$rb-dark-blue-opaque: #143357;

$rb-dark-blue: #23408F;             // For header,menu
$rb-basic-blue:#006DBA;             // For signs
$rb-blue: #0071CE;                   // For font, button
$rb-blue-sec: #334F9A;
$rb-white: #ffffff;                // For font
$rb-black: #202020;                // For font
$rb-grey: #8D8D90;                 // For font
$rb-grey-row: #F9F9F9;
$rb-grey-home: #F0F0F2;
$rb-grey-text : #76777B;
$rb-grey-new: #F5F5F5;
$rb-lighter-grey: #EAEAEA;             // For borders
$rb-light-grey: #D7D8D9;             // For borders
$rb-dark-grey: #909090;              // For font, button2
$rb-darker-grey: #7F8083;
$rb-yellow: #FA9016;                 // For button
$rb-yellow-light: #FB9B26;
$rb-dark-yellow: #F18C18;            // For font
$rb-purple: #766ED7;                 // For graphics
$rb-light-shadow: #0000001a;         //Shadow
$rb-blue-border: #006DBA;
$rb-green: #00C283;
$red: #dc3545;
$rb-red-Light: #FC745A;
$be-blue: #314175;                   //Buyers Edge Blue
$rb-fsr: #6BA068;                   //Food Service Rewards green

$background-image: url('../../assets/images/rebate-bg.png');

$primary: var(--primary);
$secondary: var(--secondary);
$tertiary: var(--tertiary);

// Fonts 
$bodyFont: var(--bodyFont);
$lightFont: var(--lightFont);
$mediumFont: var(--mediumFont);
$boldFont: var(--boldFont);